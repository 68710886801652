import cliSelect from 'cli-select'
import { question } from 'readline-sync'
import { resolve } from 'path'
import { existsSync } from 'fs'

import { white } from './utils/colors'
import logg from './utils/logg'
import generate from './generate'

cliSelect({
  cleanup: false,
  values: [
    'Init seed version',
    'Init full version (all pages from the preview)',
    'Enter config file path',
  ],
  valueRenderer: (value, selected) => (selected ? white(value) : value),
})
  .then((response) => {
    if (response.id === 0) {
      const resolvedSeedFile = resolve(__dirname, 'config-seed.json')
      logg('Generating seed version...', 'green')
      logg('Config: ' + resolvedSeedFile, 'green')
      generate(resolvedSeedFile)
    }
    if (response.id === 1) {
      const resolvedFullFile = resolve(__dirname, 'config-full.json')
      logg('Generating full preview version...', 'green')
      logg('Config: ' + resolvedFullFile, 'green')
      generate(resolvedFullFile)
    }
    if (response.id === 2) {
      const configPath = question('Enter relative config file path: ')
      const resolvedCustomFile = resolve(configPath)
      if (existsSync(resolvedCustomFile)) {
        logg('Generating version with custom config file...', 'green')
        logg('Config: ' + resolvedCustomFile, 'green')
        generate(resolvedCustomFile)
      } else {
        logg('Config file not found: ' + resolvedCustomFile, 'red')
      }
    }
  })
  .catch(() => {
    logg('Init skipped.', 'red')
  })
